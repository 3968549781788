.inputHeigth {
    line-height: normal !important;
    width: 110px;
    height: 40px;
    border: 1px solid black;
}

.inputHeigth:-moz-drag-over {
    background-color: red;
}

.listBoxTextPage {
    overflow-y: scroll;
    height: 55vh;
}

.listBoxTextPage::-webkit-scrollbar {
    background-color: transparent;
}

.listBoxTextPage::-webkit-scrollbar-thumb {
    background-color: #ccc;
    width: 3px;
}

.listBoxTextPage {
    /* Foreground, Background */
    scrollbar-color: #999 #333;
}

.listBoxTextPage::-webkit-scrollbar {
    width: 10px;
    /* Mostly for vertical scrollbars */
    height: 5px;
    /* Mostly for horizontal scrollbars */
}

.listBoxTextPage::-webkit-scrollbar-thumb {
    /* Foreground */
    background-color: #ccc;
    border-radius: 5px;
}

.listBoxTextPage::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.30);
    border-radius: 5px;
}