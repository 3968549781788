.default-card-ssvr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 750px;
    max-width: 900px;
}

.container-ssvr {}

.wrapper-ssvr {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    align-items: center;
}

.wrapper-ssvr h2 {
    flex: 1;
}

.wrapper-microphone-vr {
    flex: 1;
    margin-left: 40px;
}

.text-instructions {
    float: right;
    padding: 20px;
    word-break: break-word;
}

.wrapper-ssvr .audio-attempts {
    display: block;
    margin: 0 0 1rem 6rem;
}

.wrapper-ssvr .audio-attempts span {
    padding-left: 0;
    padding-bottom: 0;
}

.audio-container {
    width: fit-content;
    align-items: center;
    justify-content: flex-start !important;
    gap: 1.5rem;
    display: flex;
    cursor: pointer;
}

.audio-container span {
    padding-right: 0 !important;
    font-size: 18px;
    font-weight: bold;
}


.audio-icon:hover {
    background-color: rgb(109, 194, 228);
}

.wrapper-microphone-vr .audio-icon {
    background-color: rgb(86, 189, 230);
    height: 60px;
    width: 60px;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.wrapper-microphone-vr .icon-step {
    font-size: 24px;
}

.overall-score-ssvr {
    margin: 0 auto;
    margin-top: 19px;
    background-color: #fff;
    color: black;
    min-height: 120px;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 350px;
    max-width: 50%;
    border-radius: 20px;
}

.overall-score-ssvr .wrapper {
    display: flex;
    gap: 1rem;
    width: 100%;
    align-items: center;
}

.overall-score-ssvr .overall-score-info-ssvr {
    width: 95%;
    display: flex;
    gap: 1rem;
    align-items: center;
    margin: 15px 0;
}

.overall-score-ssvr .overall-score-info-ssvr .percent {
    font-size: 20px;
}

.overall-score-ssvr .word-paragraph-ssvr {
    width: 100%;
    display: flex;
    align-items: center;
    gap: .5rem;
    flex-wrap: wrap;
}


.overall-score-ssvr .word-paragraph-ssvr span:nth-child(2) {
    font-weight: bold;
}


.word-paragraph-ssvr {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0px;
}

.word-paragraph-ssvr span:last-child {
    margin-right: 0px;
}

.word-paragraph-ssvr .red-word {
    color: red;
}

.word-paragraph-ssvr .orange-word {
    color: orange;
}

.word-paragraph-ssvr .green-word {
    color: green;
}

.overall-score-ssvr .start,
.overall-score-ssvr .stop {
    background-color: #42b983;
    color: white;
    border-radius: 50px;
    margin-bottom: 0;
    height: 46px;
    width: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, transform 0.3s;
    outline: none !important;
    border: none;
    font-size: 16px;
    margin-right: 0;
}

.stop {
    background-color: #e74c3c;
    color: white;
    border-radius: 50px;
}

.start:hover {
    background-color: #349b6d;
    transform: scale(1.05);
}

.stop:hover {
    background-color: #b13021;
    transform: scale(1.05);
}