.fluency-sentence{
    flex: 2;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.fluency-model{
    flex: 1;
    background-color: rgb(0,48,87);
    padding: 20px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fluency-model-title{
    font-weight: bold;
    flex: 1;
}

.fluency-model-text{
    flex: 3;
    display: flex;
    align-items: center;
    text-align: center;
    white-space: pre-line
}