.illustrated-container{
    display: flex;
    flex: 1;
    height: 100%;
}

.illustrated-img{
flex: 1;
object-fit: contain;
width: 300px;
height: 300px;
margin-right: 10px;
}

.illustrated-words-container{
    flex: 2;
}

.illustrated-word{
    padding: 10px;
    background-color: white;
    color: black;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.illustrated-word-hover:hover{
    cursor: -webkit-grab;
}

.illustrated-word-hover:active{
    cursor: pointer;
}

.illustrated-word:nth-child(odd) > span:first-child{
    font-weight: bold;
    color: rgb(42, 59, 206)
}

.illustrated-word:nth-child(even) > span:first-child{
    font-weight: bold;
    color: rgb(240, 74, 74)
}