.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

@media (max-width: 767px) {
    .popup-overlay {
        height: 80%;
        background: none;
    }
}


.popup-container {
    background: white;
    padding: 10px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    width: 50%;
    max-width: 500px; 
    height: auto;
    position: relative;
}

@media (max-width: 767px) {
    .popup-container {
        width: 100%;
    }
}

.popup-image {
    max-width: 85%;
    height: auto;
    margin-bottom: 15px;
}

.popup-text {
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 600;
}

.popup-close-button {
    position: absolute;
    top: -2px;
    right: 10px;
    background: none;
    border: none;
    font-size: 30px;
    cursor: pointer;
    color: #333;
    font-weight: bold;
}

.popup-close-button:hover {
    color: #ff0000;
}