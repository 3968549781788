.dialog-drop-content{
    position: absolute;
    width: 100%;
    height: 100%;
}

.dialog-main-container{
    flex-direction: row-reverse;
}

.dialog-responses-container{
    padding: 0px 20px;
    overflow: display;
    clear: both;
    bottom: 67px;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.dialog-conversation-container{
    overflow: hidden; 
    clear: both;
    flex: 1;
    position: relative;
    background-color: white;
    padding: 15px;
    display: block;
    height: calc(100% + 40px);
    margin-right: -20px;
}

.dialog-word:nth-child(odd) > .dialog-icon-container {
    margin-left: 15px;
    align-items: center;
    display: flex;
    margin-bottom: 5px;
    position: absolute;
    top: -25px;
    right: 25px;
    color: blue;
    font-weight: bold;
}

.dialog-word:nth-child(even) > .dialog-icon-container {
    margin-right: 15px;
    align-items: center;
    display: flex;
    margin-bottom: 5px;    
    position: absolute;
    top: -25px;
    color:red;
    font-weight: bold;
}

.dialog-word{
    display: flex;
    position: relative;
    padding: 0px 10px;
    width: 100%;
    margin-top: 20px;
}

.dialog-word:nth-child(odd){
    justify-content: flex-end;
    flex-direction: row;
}

.dialog-word:nth-child(even){
    justify-content: flex-end;
    flex-direction: row-reverse;
}

.dialog-word:nth-child(even) > .conversation-two-bubble {
	background: #e8f3b9;
}

.dialog-word:nth-child(odd) > .conversation-two-bubble {
	background: #69c788;
}


.dialog-word:nth-child(even) > .conversation-two-bubble:after{
	left: 0;
	border-right-color: #e8f3b9;
	border-left: 0;
	margin-left: -8px;
}

.dialog-word:nth-child(odd) > .conversation-two-bubble:after{
	right: 0;
	border-left-color: #69c788;
	border-right: 0;
	margin-right: -8px;
}

.draggable-dialog{
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
}

.dialog-grab{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 100%;
    margin-bottom: 10px;
    color: black;
    padding: 1rem;
    text-align: center;
    font-size: 1rem;
    line-height: normal;
    float: left;
    cursor: move;
    position: relative;
    padding-left: 35px;
}

.dialog-grab-icon{
    position: absolute;
    left:-30px;
    height: 60px;
    width: 60px;
    border-radius: 50px;
    background-color: rgb(86, 189, 230);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.icon-role{
    font-size: 25px;
}

.sentence-empty{
    color: rgb(117,117,117);
    text-align: center;
    margin-top: 50%;
}