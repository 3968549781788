.Card-itens-front{
    margin: 0 auto;
    height: 250px;
    width: 250px;
    background-color: #fff
  }
.carousel-img{
    width: 250px;
    height: 250px;
    vertical-align: -webkit-baseline-middle;
      
  }
.carousel-sentence{
    flex: 2;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.carousel-model{
    flex: 1;
    background-color: rgb(0,48,87);
    padding: 20px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.carousel-model-title{
    font-weight: bold;
    flex: 1;
}

.carousel-model-text{
    flex: 3;
    display: flex;
    align-items: center;
    text-align: center;
    white-space: pre-line
}