.footer{
    position: fixed;
    bottom: 0px;
    height: 80px;
    width: 100%;
    background-color: rgb(0,48,87);
    z-index: 99;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
}

@media (max-height: 500px) {
    .footer{
    height: 55px;
    }
}

.time-interval{
    color: white;
    flex: 1;
    display: flex;
    align-items: center;
    font-weight: lighter;
}

@media (max-width: 500px) { 
    .time-interval {
        display: none;
    }
}

.select-speed{
    margin-left: 5px;
    padding: 5px;
    cursor: pointer;
    font-weight: bold;
}


.footer-player {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
    width: 250px;
    margin-right: 60px;
    position: absolute;
    right: 0;
}

.icon-play{
    color: white;
    font-size: 50px;
}

.icon-step{
    color: white;
    font-size: 30px;
    transition: width 0.2s ease-out, height 0.2s ease-out;
    cursor: pointer;
}

.icon-faq{
    font-size: 25px
}

.icon-clock{
    color: white;
    font-size: 20px;
    margin-right: 5px;
}

.icon-step-container > .icon-step:hover {
    width: 35px;
    height: 35px;
    transition: width 0.2s ease-out, height 0.2s ease-out;
}

.icon-play-container {
    cursor: pointer;
    border-width: 1px;
    border-color: transparent;
    border-style: solid;
}

.icon-step-container {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.icon-play-container:hover {
    border-width: 1px;
    border-color: transparent;
    border-radius: 50px;
    border-style: solid;
    box-shadow: 0px 0px 10px white;
}

.icon-play-container svg {
    margin: -2px;
}

.footer-logo {
    height: 100px;
    width: 100px;
    margin-left: 10px;
    position: absolute;
    top: -25px;
    align-self: stretch;
  }