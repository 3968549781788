.listen-drag-check-maze{
    position: fixed;
    bottom: 100px;
    right: 50px;
    background-color: whitesmoke;
    color: black;
    font-weight: bold;
    padding: 10px 20px;
    cursor: pointer;
    border: 1px solid rgb(117,117,117);
    z-index: 999;
}

.drag-drop-list{
    margin-top: 80px;
}