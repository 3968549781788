.default-card-audiovr {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.audio-attempts {
    display: flex;
    align-items: center;
    justify-content: center;
}

.audio-attempts span {
    padding-left: 49px;
    padding-bottom: 23px;
}

.audio-container {
    align-items: center;
    justify-content: space-between;
    display: flex;
    cursor: pointer;
}

.audio-container span {
    padding-right: 45px;
}

.audio-icon:hover {
    background-color: rgb(109, 194, 228);
}

.audio-icon {
    background-color: rgb(86, 189, 230);
    height: 75px;
    width: 75px;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.overall-score-audiovr {
    background-color: #def0a5;
    color: black;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.overall-score-audiovr .overall-score-info {
    width: 95%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 15px;
}

.overall-score-audiovr .word-paragraph-audiovr {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 10px 0px 5px;
}

.overall-score-audiovr .word-paragraph-audiovr span:nth-child(2) {
    font-weight: bold;
}

.word-paragraph-audiovr {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0px;
}

.word-paragraph-audiovr span {
    margin-right: 6px;
}

.word-paragraph-audiovr span:last-child {
    margin-right: 0px;
}

.word-paragraph-audiovr .red-word {
    color: red;
}

.word-paragraph-audiovr .orange-word {
    color: orange;
}

.word-paragraph-audiovr .green-word {
    color: green;
}

.start-audiovr,
.stop-audiovr {
    background-color: #42b983;
    color: white;
    border-radius: 50px;
    margin-bottom: 6px;
    height: 46px;
    width: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, transform 0.3s;
    outline: none !important;
    border: none;
    font-size: 16px;
    margin-right: 6px;
}

.stop-audiovr {
    background-color: #e74c3c;
    color: white;
    border-radius: 50px;
}

.start-audiovr:hover {
    background-color: #349b6d;
    transform: scale(1.05);
}

.stop-audiovr:hover {
    background-color: #b13021;
    transform: scale(1.05);
}