.microphone-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 6px;
    flex-direction: column;
}
.microphone-container .container-button,
.microphone-container .audio-player {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
    width: 100%;
}

.microphone-container span {
    font-size: 18px;
    font-weight: bold;
}

.send-button,
.start-button,
.stop-button {
    background-color: #42b983;
    color: white;
    border-radius: 50px;
    margin-bottom: 6px;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, transform 0.3s;
    outline: none !important;
    border: none;
}

.send-button {
    font-size: 18px;
}

.start-button {
    font-size: 22px;
}

.pause-icon.stop-button {
    font-size: 18px;
}

.stop-button {
    font-size: 20px;
}

.send-button,
.start-button {
    background-color: #42b983;
    color: white;
}

.stop-button {
    background-color: #e74c3c;
    color: white;
    border-radius: 50px;
}

.send-button:hover,
.start-button:hover {
    background-color: #349b6d;
    transform: scale(1.05);
}

.stop-button:hover {
    background-color: #b13021;
    transform: scale(1.05);
}

.custom-audio-player {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer;
}

.play-button.playing {
    transform: scale(1.2);
}
