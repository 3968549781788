.workbook-container{
    display: flex;
    flex-direction: column;
    text-align: center;
}

.workbook-img{
    object-fit: contain;
    height: 200px;
}

.workbook-button{
    background-color: white;
    color: rgb(0,48,87);
    font-weight: bold;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 22px;
}

.workbook-button:hover{
    background-color: whitesmoke;
}