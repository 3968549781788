.list-group-item {
    height: 90px;
    width: 90px;
    color: #696969
}
.list-group-item, .list-group-item.active{
    border-radius: 50% !important;
    line-height: 2.5;
    text-align: center;
    width: 70px;
    height: 70px;
    
}
.list-group-item.active{
    background-color: #072F57;
    color: #fff
}

.list-group {
    padding: 40px;
    padding-bottom: 0px;
    background-color: #fff !important;
    list-style-type: none;
    overflow:hidden; 
    overflow-x: hidden;
    height:100%; 
    /* width: 180px; */
}
.wiz-tab-leftnav{  
    position: fixed;
    width: 170px;
    height: calc(100vh - 170px);
}
@media (max-height: 500px) { 
  .navbar-adjust {
    min-height: 50px !important;
  }
}

@media (max-width: 500px) { 
  .wiz-tab-leftnav{
    width: 90px !important;
    height: 100%;
  }

  .content-class {
    margin-left: 100px;
  }

  .list-group {
    padding: 5px;
  }
}

.navCounter{
    font-size: 13px;
}
.vl {
    border-left: 1.5px solid rgba(0, 0, 0, 0.250);
    height: 27px;
    margin-bottom: -30px;
    margin-top: 10px;
  }
  .navText{
    font-size: 10px;
    color: #696969;
    text-align: center
  }
  .left-nav {
    width: 170px;
  }
  .left-nav li{
    margin-bottom: 45px;
  }

  .wiz-tab-leftnav > .card > .card-body {
      overflow-y: scroll;
      overflow-x: hidden;
  }
  .li-style{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  a:hover{
    color: #696969;
    text-decoration: none;
  }

  .li-style:last-child > div {
    border-left: 0px solid rgba(0, 0, 0, 0.250)
  }