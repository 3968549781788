.conversation-trio-bg-container{
    background-image: url("../images/conversation-bg-adult-new.png");
    display: block;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    position: relative;
    background-repeat: no-repeat;
}

.boneco {
    background-image: url("../images/boneco_middle-right.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 100px;
    height: 30%;
    position: absolute;
    top: 58%;
    left: 40%;
}

.conversation-card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% + 40px);
    width: calc(100% + 40px);
    margin-top: -20px;
    margin-left: -20px;
}

.conversation-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 110vh;

}

.baloon {
    position: absolute;
    width: 250px;
    text-align: center;

}

.speech-bubble {
	position: absolute;
	background: white;
    border-radius: 15px;
    padding: 10px;
    color: black;
    width: 230px;
    text-align: center;
}

.speech-bubble:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 30px solid transparent;
	border-top-color: white;
	border-bottom: 0;
	border-left: 0;
	margin-left: -17.5px;
	margin-bottom: -28px;
}