.container {
  min-width: 100%;
  min-height: 100%;
}
.cardsContainer{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.dustbinCard {
  background-color: white;
  color: black;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15rem;
  height: 8rem;
  margin: 10px;
  padding: 5px;
  border-radius: 5px;
}