.default-card-double {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.collapsible-wrapper {
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin: 0 auto;
    align-items: center;
}

.collapsible-wrapper h2 {
    padding-right: 20px;
}

.highlight-icon-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.highlight-icon {
    background-color: rgb(65, 182, 230);
    padding: 10px;
    cursor: pointer;
    border-radius: 50px;
    height: 50px;
    width: 50px;
}

.highlight-icon:hover {
    background-color: rgb(86, 189, 230);
}

.h2-highlight>.Collapsible>div:nth-child(1)>div {
    padding: 0px;
}

.h2-highlight>.Collapsible>div:nth-child(1) {
    cursor: auto;
}

.h2-highlight>.Collapsible>div>div {
    cursor: auto;
}

.Collapsible>div:nth-child(1) {
    background-color: rgb(0, 48, 87);
    padding: 20px;
    cursor: pointer;
}

.audio-attempts {
    display: flex;
    align-items: center;
    justify-content: center;
}

.audio-attempts span {
    padding-left: 49px;
    padding-bottom: 23px;
}

.audio-container {
    width: 270px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    cursor: pointer;
}

.audio-container span {
    padding-right: 45px;
}


.audio-icon:hover {
    background-color: rgb(109, 194, 228);
}

.audio-icon {
    background-color: rgb(86, 189, 230);
    height: 75px;
    width: 75px;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.overall-score-double {
    margin: 0 auto;
    margin-top: 19px;
    background-color: #def0a5;
    color: black;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.overall-score-double .overall-score-info {
    width: 95%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 15px
}

.overall-score-double .word-paragraph-double {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 10px 0px 5px;
}


.overall-score-double .word-paragraph-double span:nth-child(2) {
    font-weight: bold;
}


.word-paragraph-double {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0px;
}

.word-paragraph-double span {
    margin-right: 6px;
}

.word-paragraph-double span:last-child {
    margin-right: 0px;
}

.word-paragraph-double .red-word {
    color: red;
}

.word-paragraph-double .orange-word {
    color: orange;
}

.word-paragraph-double .green-word {
    color: green;
}

.start,
.stop {
    background-color: #42b983;
    color: white;
    border-radius: 50px;
    margin-bottom: 6px;
    height: 46px;
    width: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, transform 0.3s;
    outline: none !important;
    border: none;
    font-size: 16px;
    margin-right: 6px;
}

.stop {
    background-color: #e74c3c;
    color: white;
    border-radius: 50px;
}

.start:hover {
    background-color: #349b6d;
    transform: scale(1.05);
}

.stop:hover {
    background-color: #b13021;
    transform: scale(1.05);
}