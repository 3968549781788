.listen-drag-left-container{
}

.listen-drag-left-icon{
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.listen-drag-left-icon-container{
    background-color: rgb(86, 189, 230);
    height: 80px;
    width: 80px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.listen-drag-check{
    position: fixed;
    bottom: 100px;
    right: 50px;
    background-color: whitesmoke;
    color: black;
    font-weight: bold;
    padding: 10px 20px;
    cursor: pointer;
    border: 1px solid rgb(117,117,117);
}