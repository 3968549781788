.illustrated-pages-container{
    flex: 2;
    background-color: white;
    color: black;
    padding: 25px 20px;
    position: relative;
}

.change-language-container{
    position: absolute;
    display: flex;
    flex-direction: row;
    top: -17px;
    right: 0px;
    margin-right: 10px;
}

.language-select:hover{
    filter: drop-shadow(0px 0px 4px black);
    transition: filter 0.2s ease-out;
}

.language-select{
    border-radius: 50px;
    width: 35px;
    height: 35px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: bold;
    padding-bottom: 2px;
    color: white;
    margin-right: 10px;
    cursor: pointer;
    transition: filter 0.2s ease-out;
}