.highlight-icon-container{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.highlight-icon{
    background-color: rgb(65,182,230);
    padding: 10px;
    cursor: pointer;
    border-radius: 50px;
    height: 50px;
    width: 50px;
}

.highlight-icon:hover{
    background-color: rgb(86, 189, 230);
}

.h2-highlight > .Collapsible > div:nth-child(1) > div {
    padding: 0px;
}

.h2-highlight > .Collapsible > div:nth-child(1){
    cursor: auto;
}

.h2-highlight > .Collapsible > div > div {
cursor: auto;
}

.Collapsible > div:nth-child(1){
    background-color: rgb(0,48,87);
    padding: 20px;
    cursor: pointer;
  }
