.conversation-two{
    height: 100%;
    width:600px;
    background-color: lightgrey;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.conversation-two-bubble {
	position: relative;
    border-radius: .4em;
    padding: 5px 10px;
    margin-bottom: 10px;
    color: black;
}

.conversation-two-word:nth-child(even) > .conversation-two-bubble {
	background: #e8f3b9;
}

.conversation-two-word:nth-child(odd) > .conversation-two-bubble {
	background: #69c788;
}

.conversation-two-bubble:after {
	content: '';
	position: absolute;
	top: 50%;
	width: 0;
	height: 0;
	border: 8px solid transparent;
	margin-top: -8px;
}

.conversation-two-word{
    display: flex;
}

.conversation-two-word:nth-child(odd){
    justify-content: flex-end;
    flex-direction: row;
}

.conversation-two-word:nth-child(even){
    justify-content: flex-end;
    flex-direction: row-reverse;
}

.conversation-two-word:nth-child(even) > .conversation-two-bubble:after{
	left: 0;
	border-right-color: #e8f3b9;
	border-left: 0;
	margin-left: -8px;
}

.conversation-two-word:nth-child(odd) > .conversation-two-bubble:after{
	right: 0;
	border-left-color: #69c788;
	border-right: 0;
	margin-right: -8px;
}

.conversation-two-word:nth-child(odd) > .conversation-two-icon-container {
    margin-left: 15px;
    align-items: center;
    display: flex;
    margin-bottom: 5px;
}

.conversation-two-word:nth-child(even) > .conversation-two-icon-container {
    margin-right: 15px;
    align-items: center;
    display: flex;
    margin-bottom: 5px;
}

.conversation-two-icon {
    width: 25px;
    height: 20px;
}