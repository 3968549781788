.simple-highlight-container{
    padding: 20px;
    margin: 20px 0px;
    background-color: white;
    text-align: center;
}

.speak-right-single-container {
    display: flex;
    justify-content: center;
    padding: 20px;
    margin: 20px 0px;    
    font-weight: bold;
    width: 100%;
}

.simple-active{
    background-color: rgb(0,48,87);
}

