.webtalk-bg {
    /* background-image: url("../images/web_talk1.jpg"); */
    display: block;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    position: relative;
    background-repeat: no-repeat;
}

.web_talk1{
background-image: url("../images/web_talk1.jpg");
}

.web_talk2{
background-image: url("../images/web_talk2.jpg");
}
.web_talk3{
background-image: url("../images/web_talk3.jpg");
}

.webtalk-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 110vh;
}

.webtalk-bubble {
	position: absolute;
	background: white;
    border-radius: .4em;
    padding: 10px;
    color: black;
    box-shadow: 0px 0px 10px 1px black;
}

.webtalk-bubble:after {
	content: '';
	position: absolute;
	top: 0;
	left: 20%;
	width: 0;
	height: 0;
	border: 20px solid transparent;
	border-bottom-color: white;
	border-top: 0;
	border-left: 0;
	margin-left: -10px;
	margin-top: -20px;
}


.webtalk-bubble2 {
    position: absolute;
    box-shadow: 0px 0px 10px 1px black;
	background: white;
    border-radius: .4em;
    padding: 10px;
    color: black;
    max-width: 300px;
}

.webtalk-bubble2:after {
	content: '';
	position: absolute;
	top: 0;
	left: 70%;
	width: 0;
	height: 0;
	border: 20px solid transparent;
	border-bottom-color: white;
	border-top: 0;
	border-left: 0;
	margin-left: -10px;
	margin-top: -20px;
}