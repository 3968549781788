.words-context-container {
    background-color: white;
    margin-bottom: 25px;
    padding: 20px;
    color: black;
    flex: 1;
    justify-content: center;
    display: flex;
}

.words-context-container:nth-child(1) {
    margin-right: 10px;
}

.words-context-container:nth-child(2) {
    margin-left: 10px;
}

.phrase-context-container {
    background-color: rgb(0,48,87);
    padding: 20px;
    display: flex;
    justify-content: center;
}