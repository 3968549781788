html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.spinner-border {
  width: 25px;
  height: 25px;
  border: 2px solid currentColor;
  border-right-color: transparent;
}

.spinner-border-lessons {
  width: 18px;
  height: 18px;
  border: 2px solid currentColor;
  border-right-color: transparent;
  animation: spinner-border .75s linear infinite;
  vertical-align: text-bottom;
  display: inline-block;
  border-radius: 50%;
}

.all-lessons-title {
  color: black;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.all-lessons-container {
  border-radius: 3px;
  box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.5);
  padding: 10px;
  margin-bottom: 15px;
}

.home-loading {
  border: 2px solid rgb(117,117,117);
  border-right-color: white;
}

.spinner-border-video {
  position: absolute;
  top: 50%;
}


.login-button{
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h2{
  display: inline;
  margin-bottom: 0px;
}

#wizme_app {
  padding: 0px;
  color: #fff;
  height: auto;
  min-height: 100%;
  min-height: 100vh; 
}

.background-image{
  background: url("../src/assets/images/background-top.svg"),
          url("../src/assets/images/background-bottom.svg");
  background-repeat: no-repeat;
  background-position: 0 0, 100% 100%;
  position: relative;
}

.background-rect {
  background-color:  #33aae3bd;
  display: inline-block;
  padding: 20px;
  min-width: 800px;
  max-width: 1600px;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.5em 1em -0.125em rgb(17 43 74 / 50%);
  border-radius: 8px;
}

.wiz-tab-emulator > .card {
  background-color: transparent;
  border: none
}

.centraliza {
  margin: auto;
}

.home-container{
  padding-top: 90px;
  color: #0E386A;
  min-height: 100%;
  min-height: 100vh;
}

.navbar {
  justify-content: space-between;
}

.match-text-item {
  margin-top: 20px;
  justify-content: center;
}

.dustbin-match{
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  padding: 1rem;
  text-align: center;
  font-size: 1rem;
  line-height: normal;
  float: left;
}

.select-language-backdrop{
  z-index: 999999;
  position: fixed;
  background-color: rgba(0,0,0,0.4);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-language-container{
  background-color: white;
  border-radius: 10px;
  margin-bottom: 20px;
  border-color: white;
  width: 400px;
  min-height: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.language-item-container{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.select-language-button{
  margin-top: 20px;
  background-color: #003057;
  margin-bottom: 25px;
  border-radius: 10px;
  border-width: 0.1px;
  height: 70px;
  border-color: white;
  align-self: center;
  justify-content: center;
  align-items: center;
  width: 90%;
  display: flex;
  cursor: pointer;
  position: relative;
}

.language-item{
  width: 100%;
  height: 75px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.language-item:hover{
  background-color: rgba(0,0,0,0.1);
  cursor: pointer;
}

.language-item:not(:nth-child(1)){
 border-top: 1px solid gray;
}

.match-image-item {
  overflow: hidden;
  clear: both;
  justify-content: center;
}

.dustbin-match-img{
  height: 100%;
  width: 100%;
}

.box-text-item{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  color: black;
  padding: 1rem;
  text-align: center;
  font-size: 1rem;
  line-height: normal;
  float: left;
  cursor: move;
  opacity: 1;
  background-color: white;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;
}

@media (max-height: 500px) {
  .left-nav {
    margin-top: 50px;
    height: calc(100% - 105px);
  }

  .home-container{
    padding-top: 50px;
  }
}

@media (max-width: 500px) {
  .match-container{
    display: flex;
    flex-direction: row;
  }
}

.main {
  color: #fff;
  padding: 90px 0 80px;
}

.content {
  display: flex;
  min-height: calc(100vh - 170px);
}

.content-class {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 50px 0;
}

.wiz-tab-emulator .card{
  width: 100%;
  height: 100%;
}

.wiz-tab-emulator {
  color: white !important;
}

.warning-center{
  position: absolute;
  top: 50%;
  left: 47%; 
  display: flex;
  align-items: center; 
  flex-direction: column;
}

.loading-lessons-container{
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.btn-block-home{
  width: 50%;
  margin-top: 15px;
}

.home-no-lessons{
  padding: 25px;
  color: rgb(117,117,117);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.wiz-tab-emulator .card {
  height: 500px ;
}

.default-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.instruction-title{
  text-align: center;
}

.instruction-text{
  text-align: center;
}

.Collapsible > div {
  width: 100%;
  text-align: center;
}

.Collapsible > div > div {
  text-align: center;
  padding: 20px;
  cursor: pointer;
}
.Collapsible {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  min-width: 400px;
}

.Collapsible > div:nth-child(1){
  background-color: rgb(0,48,87);
  padding: 20px;
  cursor: pointer;
}

.Collapsible > div:nth-child(2){
  background-color: white;
  color: rgb(0,48,87);
}

.back-header{
  display: flex;
}

.back-header > a {
  display: flex;
  align-items: center;
  color: white !important;

}

.back-header > a:hover {
  text-decoration: none;
  color: white;
  opacity: 0.6;
  cursor: pointer;
}

/* .back-header > a > svg:hover {
  color: red;
} */

.back-header > a > svg {
  margin-right: 10px;
  margin-left: 10px;
}

.login-error {
  background-color: rgba(255,0,0,0.3);
  margin-top: 15px;
  padding: 10px;
  border-radius: 5px;
  color: black;
  text-align: center;
}