.mosaic-img{
  width: 235px;
  height: 235px;
  vertical-align: -webkit-baseline-middle;
    
}
  .Card {
    width: 100%;
    height: 300px;
    /* background-color: orange; */
    margin: 0 auto;
    line-height: 15;
    /* display: inline-block;
    cursor: pointer; */
  }
  .Card .Card-Front, .Card .Card-Back {
    width: 12rem;
    height: 12rem;
    text-align: center;
    margin: 0 auto;
    /* margin-bottom: -5rem; */
  }


  .Card .Card-Front {
    margin-bottom: 20px;
    /* position: relative; */
    z-index: 1;
    /* color: #69969c; */
    /* background-color: #0e464e; */
  } 
  .Card .Card-Front.Card-Back-Flip {
    animation: back-flip-1 1s 1 forwards;
  }
  @keyframes back-flip-1 {
    0% {
      transform: rotateY(0deg);
      z-index: 1;
      transform: rotateY(90deg) perspective(15rem);
    }
    100% {
      transform: rotateY(90deg);
      z-index: -1;
    }
  }
  .Card .Card-Front.Card-Front-Flip {
    animation: front-flip-1 1s 1 forwards;
  }
  @keyframes front-flip-1 {
    0% {
      transform: rotateY(-90deg);
      z-index: -1;
    }
    50% {
      transform: rotateY(-90deg) perspective(15rem);
      z-index: -1;
    }
    100% {
      transform: rotateY(0deg);
      z-index: 1;
    }
  }
  .Card .Card-Back {
    font-size: 0.9rem;
    /* position: relative; */
    z-index: -1;
    /* top: -6rem; */
    color: #012e34;
    background-color: white;
    transform: rotateY(-90deg);
  }
  .Card .Card-Back.Card-Back-Flip {
    animation: back-flip-2 1s 1 forwards;
  }
  @keyframes back-flip-2 {
    0% {
      z-index: -1;
      transform: rotateY(-90deg) perspective(15rem);
    }
    100% {
      transform: rotate(0deg);
      z-index: 1;
    }
  }
  .Card .Card-Back.Card-Front-Flip {
    animation: front-flip-2 1s 1 forwards;
  }
  @keyframes front-flip-2 {
    0% {
      transform: rotate(0deg);
      /* z-index: 1; */
    }
    50% {
      transform: rotateY(90deg) perspective(15rem);
      /* z-index: 1; */
    }
    100% {
      transform: rotateY(90deg);
      /* z-index: -1; */
    }
  }
  .Cards {
    /* display: flex; */
    justify-content: center;
    /* flex-wrap: wrap; */
  }


  .default-card-mosaic{
    margin: 3%;
  }

  .Card-itens-front{
    margin: 0 auto;
    height: 250px;
    width: 250px;
    background-color: #fff
  }


  .Card-itens-back{
    margin: 0 auto;
    height: 250px;
    width: 250px;
    background-color: #fff;
    padding-top: 50%;
  }

